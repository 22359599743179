#googlePayButton {
  cursor: pointer;
}
#gpay-button-online-api-id {
  outline: none;
  width: 60px;
  text-align: start;
  padding: 12px 0;
  display: none;
}
